import React from 'react';
import * as ReactGA from 'react-ga';
import Layout from '../components/Shared/Layout';
import MainSection from '../components/Home/main';
import ClientLogos from '../components/Shared/ClientLogos/index';
import Journey from '../components/Home/journey';
import PrefetchContactForm from '../components/Shared/PrefetchContactForm';
import withUTMParams from '../components/HOC/withUTMParams';
import useGoogleAnalyticsPageview from '../hooks/useGoogleAnalyticsPageview';
import SelectedCaseStudies from '../components/Home/SelectedCaseStudies';

const $ = typeof window !== `undefined` ? require('jquery') : null;

const responsiveValue = {
  lg: {
    breakpoint: {
      max: Number.MAX_SAFE_INTEGER,
      min: 1201,
    },
    items: 3,
    partialVisibilityGutter: 40,
    slidesToSlide: 3,
  },
  md: {
    breakpoint: {
      max: 1200,
      min: 769,
    },
    items: 2,
    partialVisibilityGutter: 30,
    slidesToSlide: 2,
  },
  sm: {
    breakpoint: {
      max: 768,
      min: 0,
    },
    items: 1,
    partialVisibilityGutter: 30,
    slidesToSlide: 1,
  },
};

function MainPage({location, pageContext}) {
  useGoogleAnalyticsPageview('Homepage');

  return (
    <>
      <PrefetchContactForm />
      <Layout location={location} isHomepage footerProps={{showPortfolioSection: false}}>
        <MainSection variant={pageContext.variant} />
        <ClientLogos lazyLoading={false} />
        <Journey />
        <SelectedCaseStudies />
      </Layout>
    </>
  );
}

export default withUTMParams(MainPage);
