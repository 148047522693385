import React from 'react';
import {Helmet} from 'react-helmet';

// WARNING: URLs of these resources might change in future
function PrefetchContactForm() {
  return (
    <Helmet>
      <link rel="prefetch" href="https://paperform.co/__embed" as="script" />
      <link
        rel="prefetch"
        href="https://duube1y6ojsji.cloudfront.net/_b21a3a6005b7b15a278b.styles.css"
        as="style"
      />
      <link
        rel="prefetch"
        href="https://fonts.googleapis.com/css?family=Lato:300,400,700|Material+Icons"
        as="style"
      />
      <link
        rel="prefetch"
        href="https://duube1y6ojsji.cloudfront.net/dbb753ffc57bc5facfdf.form.min.js"
        as="script"
      />
    </Helmet>
  );
}

export default PrefetchContactForm;
